import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, withRouter} from 'react-router-dom'

const mapStateToProps = state => ({
  loggedIn: Boolean(state.session.currentUser)
});

const Public = ({ loggedIn, path, component: Component }) => (
  <Route 
    path={path}
    render={props => (
      loggedIn
        ? <Redirect to="/" />
        : <Component {...props} />
    )}
  />
);

const Protected = ({ loggedIn, path, component: Component }) => (
  <Route 
    path={path}
    render={props => (
      loggedIn
        ? <Component {...props} />
        : <Redirect to="/login" />
    )}
  />
);

export const PublicRoute = withRouter(connect(mapStateToProps)(Public));
export const ProtectedRoute = withRouter(connect(mapStateToProps)(Protected));